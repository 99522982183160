import React from 'react'
import { graphql } from 'gatsby'
import { RichText } from 'prismic-reactjs'
import { withPreview } from 'gatsby-source-prismic'
import Layout from '../components/layouts'
import BlogPosts from '../components/BlogPosts'
import htmlSerializer from '../utils/htmlSerializer'
import heroType from '../images/homepage-hero-text.svg'

// Query for the Blog Home content in Prismic
// export const query = graphql`
// `
// Using the queried Blog Home document data, we render the top section
// const BlogHomeHead = ({ home }) => {
//   const avatar = { backgroundImage: `url(${home.image.url})` }
//   return (
//     <div className="home-header container" data-wio-id={home.id}>
//       <div className="blog-avatar" style={avatar} />
//       <h1>{RichText.asText(home.headline)}</h1>
//       <p className="blog-description">{RichText.asText(home.description)}</p>
//     </div>
//   )
// }

export const Homepage = ({ data }) => {
  //if (!data) return null
  // Define the Blog Home & Blog Post content returned from Prismic
  //const home = data.prismicBloghome.data
  //const posts = data.allPrismicPost.edges
  //const photosets = data.allPrismicPhotogroup.edges

  return (
    <Layout>
      <div className="home__hero mb-5">
        <div className="page__content">
          <div className="copy__body__center homepage__hero__text__container">
            <img
              src={heroType}
              alt="The Work of Kent M. Wilhelm"
              className="img-fluid"
            />
          </div>
        </div>
      </div>
      <div className="page__content pt-5 mb-5">
        <div className="copy__body__center">
          <RichText render={data.prismicPage.data.body} htmlSerializer={htmlSerializer}/>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
query HomepageQuery {
  prismicPage(uid: {eq: "homepage"}) {
    data {
      body {
        type
        text
        spans {
          start
          end
          type
        }
      }
    }
  }
}
`
export default withPreview(Homepage)
